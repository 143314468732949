import React from 'react';
import TableComponent from "../../../../Component/TableComponent";
import ApiHandler from "../../../../Handler/ApiHandler";
import {dispatch} from "../../../../App";
import {TableReload} from "../../../../Action/TableAction";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import {Grid} from "@mui/material";

function InventoryManagementPreparerManagerOrderList() {

    const [carrierCount, setCarrierCount] = React.useState({
        carrier_1: 0,
        carrier_2: 0,
        carrier_4: 0,
        carrier_6: 0,
        carrier_7: 0,
        carrier_11: 0,
    });

    const columns = [
        {
            name: "number",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "clientEmail",
            label: "Email",
            options: {filter: true, sort: true}
        },
        {
            name: "channel",
            label: "Canal",
            options: {filter: true, sort: true}
        },
        {
            name: "carrier",
            label: "Transporteur",
            options: {filter: true, sort: true}
        },
        {
            name: "confirmedAt",
            label: "Date",
            options: {filter: true, sort: true}
        },
        {
            name: "orderClientState",
            label: "Etat",
            options: {filter: true, sort: true}
        }
    ];

    React.useEffect(() => {
        let interval = setInterval(() => dispatch(TableReload('api_inventory_management_preparer_manager_order_list')), 5000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <ShadowBoxComponent style={{textAlign: 'center'}}>
                        <img style={{height: 40}} src={'/image/carrier_1.png'} alt="carrier_1"/><br/>
                        <span style={{fontSize: 20, fontWeight: 700}}>{carrierCount.carrier_1}</span>
                    </ShadowBoxComponent>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <ShadowBoxComponent style={{textAlign: 'center'}}>
                        <img style={{height: 40}} src={'/image/carrier_2.png'} alt="carrier_2"/><br/>
                        <span style={{fontSize: 20, fontWeight: 700}}>{carrierCount.carrier_2}</span>
                    </ShadowBoxComponent>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <ShadowBoxComponent style={{textAlign: 'center'}}>
                        <img style={{height: 40}} src={'/image/carrier_11.png'} alt="carrier_11"/><br/>
                        <span style={{fontSize: 20, fontWeight: 700}}>{carrierCount.carrier_11}</span>
                    </ShadowBoxComponent>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <ShadowBoxComponent style={{textAlign: 'center'}}>
                        <img style={{height: 40}} src={'/image/carrier_6.png'} alt="carrier_6"/><br/>
                        <span style={{fontSize: 20, fontWeight: 700}}>{carrierCount.carrier_6}</span>
                    </ShadowBoxComponent>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <ShadowBoxComponent style={{textAlign: 'center'}}>
                        <img style={{height: 40}} src={'/image/carrier_4.png'} alt="carrier_4"/> TLN<br/>
                        <span style={{fontSize: 20, fontWeight: 700}}>{carrierCount.carrier_4}</span>
                    </ShadowBoxComponent>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <ShadowBoxComponent style={{textAlign: 'center'}}>
                        <img style={{height: 40}} src={'/image/carrier_7.png'} alt="carrier_7"/> PICKUP<br/>
                        <span style={{fontSize: 20, fontWeight: 700}}>{carrierCount.carrier_7}</span>
                    </ShadowBoxComponent>
                </Grid>
            </Grid>

            <br/>

            <TableComponent
                hideLoading={true}
                id={'api_inventory_management_preparer_manager_order_list'}
                title={'Commandes'}
                columns={columns}
                promiseData={(resolve) => {
                    ApiHandler.get({route: 'api_inventory_management_preparer_manager_order_list'},
                        (response) => {
                            let data = response.data;
                            let count = {
                                carrier_1: 0,
                                carrier_2: 0,
                                carrier_11: 0,
                                carrier_7: 0,
                                carrier_4: 0,
                                carrier_6: 0,
                            };
                            for (let index in data) {
                                if (data[index].carrierId === 1) {
                                    count['carrier_1'] += 1;
                                }
                                else if (data[index].carrierId === 6) {
                                    count['carrier_6'] += 1;
                                }
                                else if (data[index].carrierId === 2) {
                                    count['carrier_2'] += 1;
                                }
                                else if (data[index].carrierId === 7) {
                                    count['carrier_7'] += 1;
                                }
                                else if (data[index].carrierId === 11) {
                                    count['carrier_11'] += 1;
                                }
                                else if (data[index].carrierId === 11) {
                                    count['carrier_11'] += 1;
                                }
                                else if (data[index].carrierId === 4) {
                                    count['carrier_4'] += 1;
                                }

                            }

                            setCarrierCount(count);
                            resolve(data);
                        });
                }}
            />
            <br/><br/>
        </>
    );
}

export default InventoryManagementPreparerManagerOrderList;
