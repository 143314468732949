import React from 'react';
import {GridFooter, GridFooterContainer, GridPanelFooter} from "@mui/x-data-grid";
import {formatNumberToEurCurrency} from "../../Util/NumberTool";

export default function FooterWithTotalGeneral({totalGeneralValue}) {
    return (
        <GridFooterContainer>
            <GridFooter sx={{border: 'none'}}/>
            <GridPanelFooter sx={{backgroundColor: 'yellow', marginRight: '15px'}}>
                <strong>Total: {formatNumberToEurCurrency(totalGeneralValue)}</strong>
            </GridPanelFooter>
        </GridFooterContainer>
    );
}
