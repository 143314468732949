import React from 'react';
import { Article, Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { getRoutePathname } from '../../../../Handler/RouteHandler';
import TableComponent from '../../../../Component/TableComponent';
import { hasRight } from '../../../../Common/UserCommon';
import { isNumber } from '../../../../Util/NumberTool';
import ApiHandler from "../../../../Handler/ApiHandler";

function BuyPurveyorOrderList(props) {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "deposit",
            label: "Dépôt",
            options: { filter: true, sort: true }
        },
        {
            name: "amountTotalText",
            label: "Montant HT",
            options: { filter: true, sort: true }
        },
        {
            name: "stateText",
            label: "État",
            options: { filter: true, sort: true }
        },
        {
            name: "deliveryAt",
            label: "Date prévue de livraison",
            options: { filter: true, sort: true }
        },
        {
            name: "createdAt",
            label: "Date de création",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    const getAction = (row) => {
        return (
            <>
                {
                    (hasRight(authenticationReducer, 'ROLE_BUY_ORDER_PURVEYOR') || hasRight(authenticationReducer, 'ROLE_BUY_ORDER_PURVEYOR_EDIT')) ?
                        <Tooltip title={'Modifier'} placement="left">
                            <Link to={getRoutePathname('buy_order_purveyor', { id: row.id })}>
                                <IconButton><Edit style={{ color: '#17a2b8' }} /></IconButton>
                            </Link>
                        </Tooltip> : (hasRight(authenticationReducer, 'ROLE_BUY_ORDER_PURVEYOR_GET')) ?
                            <Tooltip title={'Visualiser'} placement="left">
                                <Link to={getRoutePathname('buy_order_purveyor', { id: row.id })}>
                                    <IconButton><Article style={{ color: '#28a745' }} /></IconButton>
                                </Link>
                            </Tooltip> : <IconButton disabled={true}><Edit style={{ color: '#17a2b8' }} /></IconButton>
                }
            </>
        );
    };


    return (
        <TableComponent
            id={'api_buy_purveyor_by_purveyor_order_list'}
            title={'Commandes'}
            columns={columns}
            promiseData={(resolve) => {

                ApiHandler.get({
                    route: 'api_buy_purveyor_by_purveyor_order_list',
                    params: { id: props.purveyor.id }
                },
                    (response) => {
                        let data = response.data;
                        for (let index in data) {
                            data[index].action = getAction(data[index]);
                            data[index].createdAt = moment(data[index].createdAt).format('ll');
                            data[index].deliveryAt = <span style={(!data[index].deliveryAt || moment(data[index].deliveryAt).isBefore(moment()) && data[index].state <= 2) ? { color: '#982525' } : {}}>{moment(data[index].deliveryAt).format('ll')}</span>;
                            data[index].amountTotalText = isNumber(data[index].amountTotal) ? parseFloat(data[index].amountTotal).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                        }
                        resolve(data);
                    });
            }}
        />
    );
}

export default BuyPurveyorOrderList;
