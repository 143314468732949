import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import TitleComponent from "../../../Component/TitleComponent";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {dispatch} from "../../../App";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {Link, useParams} from "react-router-dom";
import {Dialog, DialogActions, DialogContent, DialogTitle, Fade, Grid, IconButton, Slide, Tooltip} from "@mui/material";
import {makeStyles} from "@mui/styles";
import TableComponent from "../../../Component/TableComponent";
import {Article, CloudDownload, Face, FaceRetouchingOff, LocalShipping} from "@mui/icons-material";
import {isNumber} from "../../../Util/NumberTool";
import ButtonComponent from "../../../Component/ButtonComponent";
import {initFormHandler} from "../../../Handler/FormHandler";
import SelectComponent from "../../../Component/SelectComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import TabsComponent from "../../../Component/TabsComponent";
import moment from "moment";
import {TableReload} from "../../../Action/TableAction";

function ShopSaleOrderClient() {
    const params = useParams();
    const classes = useStyles();

    const [loading, setLoading] = React.useState(true);
    const [order, setOrder] = React.useState(null);
    const [litiges, setLitiges] = React.useState([]);
    const [isLitiges, setIsLitiges] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [openDialog, setOpenDialog] = React.useState(false);
    const [isLoadingForm, setIsLoadingForm] = React.useState(false);
    const [hasLitige, setHasLitige] = React.useState(false);
    const [isLoadingCancel, setIsLoadingCancel] = React.useState(false);
    const [showCancelDialog, setShowCancelDialog] = React.useState(false);

    const [byProducts, setByProducts] = React.useState([]);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "sku",
            label: "SKU",
            options: {filter: true, sort: true}
        },
        {
            name: "name",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "amountText",
            label: "Quantité prévue",
            options: {filter: true, sort: true}
        },
        {
            name: "amountShippedText",
            label: "Quantité expédiée",
            options: {filter: true, sort: true}
        },
        {
            name: "priceOriginalHTText",
            label: "Prix d'origine HT unitaire",
            options: {filter: true, sort: true}
        },
        {
            name: "priceOriginalTTCText",
            label: "Prix d'origine TTC unitaire",
            options: {filter: true, sort: true}
        },
        {
            name: "priceDiscountHTText",
            label: "Remise HT unitaire",
            options: {filter: true, sort: true}
        },
        {
            name: "priceDiscountTTCText",
            label: "Remise TTC unitaire",
            options: {filter: true, sort: true}
        },
        {
            name: "priceHTText",
            label: "Prix final HT unitaire",
            options: {filter: true, sort: true}
        },
        {
            name: "priceTTCText",
            label: "Prix final TTC unitaire",
            options: {filter: true, sort: true}
        }
    ];
    const columnsLitiges = [
        {
            name: "uuid",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "type",
            label: "Type",
            options: {filter: true, sort: true}
        },
        {
            name: "state",
            label: "Statut",
            options: {filter: true, sort: true}
        },
        {
            name: "reason",
            label: "Raison",
            options: {filter: true, sort: true}
        },
        {
            name: "author",
            label: "Auteur",
            options: {filter: true, sort: true}
        },
        {
            name: "litigeAtText",
            label: "Date de création",
            options: {filter: true, sort: true}
        },
        {
            name: "dealAtText",
            label: "Date de cloture",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Visualiser'} placement="left">
                    <Link to={getRoutePathname('client_service_order_client_litige', {uuid: row.uuid})}>
                        <IconButton><Article style={{color: '#17a2b8'}}/></IconButton>
                    </Link>
                </Tooltip>
            </>
        );
    };
    const getLinkCarrier = (id, number) => {
        if (id === 1) {
            return 'https://www.mondialrelay.fr/suivi-de-colis?numeroExpedition=' + number + '&language=FR'
        }
        else if (id === 2 || id === 4) {
            return 'https://www.chronopost.fr/expedier/inputLTNumbersNoJahia.do?listeNumeros=' + number;
        }
        else if (id === 3 || id === 5) {
            return 'https://colisprive.com/moncolis/pages/detailColis.aspx?lang=FR&numColis=' + number;
        }
    };

    // Form
    const [optionsOrderClientLitigeType, setOptionsOrderClientLitigeType] = React.useState([]);
    const [form, setForm] = React.useState({
        orderClientLitigeType: {
            name: 'orderClientLitigeType',
            label: 'Type',
            textHelper: 'Choisissez un type de litige.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        reason: {
            name: 'reason',
            label: 'Raison',
            textHelper: 'Saisissez une raison.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const handlerForm = initFormHandler(form, setForm);
    const saveLitige = () => {
        if (handlerForm.checkError() < 1) {
            handlerForm.setFormLoading(true);
            setIsLoadingForm(true);
            dispatch(LinearProgressMainLayoutActivate());

            let data = handlerForm.getData();
            let amountByProducts = [];

            for (let index in data) {

                if (parseInt(index)) {
                    amountByProducts.push({
                        'amount': data[index],
                        'byProduct': parseInt(index)
                    });
                }
            }
            ApiHandler.post({
                route: 'api_sale_order_client_litige',
                data: {
                    orderClientLitigeType: data.orderClientLitigeType,
                    reason: data.reason,
                    amountByProducts: amountByProducts
                },
                params: {uuid: params.uuid}
            }, (response) => {
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Litige enregistré avec succès.',
                            variant: 'success',
                        })
                    );

                    setOpenDialog(false);
                    setReload(!reload);
                }
                else if (response.status === 400) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }

                dispatch(LinearProgressMainLayoutDeactivate());
                handlerForm.setFormLoading(false);
                setIsLoadingForm(false);
            });
        }
    };
    const cancelOrder = () => {
        setIsLoadingCancel(true);
        dispatch(LinearProgressMainLayoutActivate());
        ApiHandler.post({
            route: 'api_sale_order_client_cancel',
            params: {uuid: params.uuid}
        }, (response) => {
            if (response.status === 200) {
                dispatch(
                    SnackbarOpen({
                        text: 'Commande annulée avec succès.',
                        variant: 'success',
                    })
                );
                setShowCancelDialog(false);
                setReload(!reload);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }

            dispatch(LinearProgressMainLayoutDeactivate());
            setIsLoadingCancel(false);
        });
    };

    React.useEffect(() => {
        setLoading(true);

        ApiHandler.get({route: 'api_select_order_client_litige_type'}, (response) => {
            setOptionsOrderClientLitigeType(response.data)
        });

        setBreadcrumbs({
            title: 'Visualisation de la commande',
            context: 'Vente',
            description: ''
        });

        ApiHandler.get({
            route: 'api_sale_order_client',
            params: {uuid: params.uuid}
        }, (response) => {
            if (response.status === 200) {
                setOrder(response.data);
                setLitiges([...response.data.orderClientLitiges] ?? []);
                setLoading(false);
                setTimeout(() => {
                    dispatch(TableReload('api_sale_order_client_litige'))
                }, 100);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }, [reload, params.uuid]);

    return (
        <ContentViewComponent loading={loading} breadcrumbs={breadcrumbs}>
            {order && <>
                <Grid container spacing={1}>
                    <Fade in={true} {...{timeout: 500}}>
                        <Grid item xs={12} sm={12} md={12} lg={8} xl={9}>
                            <ShadowBoxComponent style={{height: '100%'}}>
                                <span className={classes.state}>{order.state}</span>
                                <TitleComponent title={
                                    <span>
                                        {order.isGuest ? <Tooltip title={'Guest'} placement="left"><FaceRetouchingOff style={{color: '#ffc107', fontSize: 22, verticalAlign: 'middle'}}/></Tooltip> : <Face style={{color: '#28a745', fontSize: 22, verticalAlign: 'middle'}}/>} Information Commande client
                                        {isLitiges && <span className={classes.litige}>En litige</span>}
                                    </span>
                                }/>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={6}>
                                        <p style={{fontSize: 12, margin: 2}}><span style={{fontWeight: 700}}>
                                            Référence</span> : {order.number ?? '-'}
                                            {order.number && <Tooltip title={'Télécharger la facture'} placement="right">
                                                <IconButton disabled={order.stateId < 5} onClick={() => {
                                                    fetch(ApiHandler.route({route: 'api_sale_order_invoice_download', params: {uuid: order.uuid}}), {method: 'GET', mode: 'no-cors', referrerPolicy: 'no-referrer'})
                                                        .then(res => res.blob())
                                                        .then(res => {
                                                            const aElement = document.createElement('a');
                                                            aElement.setAttribute('download', order.clientUuid + '.pdf');
                                                            const href = URL.createObjectURL(res);
                                                            aElement.href = href;
                                                            aElement.setAttribute('target', '_blank');
                                                            aElement.click();
                                                            URL.revokeObjectURL(href);
                                                        });
                                                }}><CloudDownload style={{color: (order.stateId < 5 ? '#838d99' : '#006500'), fontSize: 17, position: 'absolute', right: -5, top: -2}}/></IconButton>
                                            </Tooltip>}
                                        </p>
                                        <p style={{fontSize: 12, margin: 2}}><span style={{fontWeight: 700}}>
                                            Nom du client</span> : {order.clientName}
                                        </p>
                                        <p style={{fontSize: 12, margin: 2}}><span style={{fontWeight: 700}}>Email du client</span> : {order.clientEmail}</p>
                                        <p style={{fontSize: 12, margin: 2}}><span style={{fontWeight: 700}}>Canal</span> : {order.channel}</p>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={4} xl={3}>
                                        <p style={{fontSize: 12, margin: 2}}><span style={{fontWeight: 700}}>Adresse de facturation</span></p>
                                        <p style={{fontSize: 12, margin: 2}}>{order.addressBilling.firstname} {order.addressBilling.lastname}</p>
                                        <p style={{fontSize: 12, margin: 2}}>{order.addressBilling.address1}</p>
                                        <p style={{fontSize: 12, margin: 2}}>{order.addressBilling.address2}</p>
                                        <p style={{fontSize: 12, margin: 2}}>{order.addressBilling.city}</p>
                                        <p style={{fontSize: 12, margin: 2}}>{order.addressBilling.zipCode}</p>
                                        <p style={{fontSize: 12, margin: 2}}>{order.addressBilling.phone}</p>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={4} xl={3}>
                                        <p style={{fontSize: 12, margin: 2}}><span style={{fontWeight: 700}}>Adresse de d'expédition</span></p>
                                        <p style={{fontSize: 12, margin: 2}}>{order.addressShipping.firstname} {order.addressShipping.lastname}</p>
                                        <p style={{fontSize: 12, margin: 2}}>{order.addressShipping.address1}</p>
                                        <p style={{fontSize: 12, margin: 2}}>{order.addressShipping.address2}</p>
                                        <p style={{fontSize: 12, margin: 2}}>{order.addressShipping.city}</p>
                                        <p style={{fontSize: 12, margin: 2}}>{order.addressShipping.zipCode}</p>
                                        <p style={{fontSize: 12, margin: 2}}>{order.addressShipping.phone}</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                                        <table>
                                            <tbody style={{fontSize: 12}}>
                                            <tr>
                                                <td style={{fontWeight: 700}}>Prix sans remise</td>
                                                <td style={{textAlign: 'right', padding: '0 5px'}}>{isNumber(order.priceOriginalHT) ? parseFloat(order.priceOriginalHT).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-'} € HT</td>
                                                <td style={{textAlign: 'right', padding: '0 5px'}}>{isNumber(order.priceOriginalTTC) ? parseFloat(order.priceOriginalTTC).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-'} € TTC</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}><hr/></td>
                                            </tr>
                                            <tr>
                                                <td style={{fontWeight: 700}}>Prix payé</td>
                                                <td style={{textAlign: 'right', padding: '0 5px'}}>{isNumber(order.payment.priceHT) ? parseFloat(order.payment.priceHT).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-'} € HT</td>
                                                <td style={{textAlign: 'right', padding: '0 5px'}}>{isNumber(order.payment.priceTTC) ? parseFloat(order.payment.priceTTC).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-'} € TTC</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}><hr/></td>
                                            </tr>
                                            <tr>
                                                <td style={{fontWeight: 700}}>Total TTC</td>
                                                <td style={{textAlign: 'right', padding: '0 5px'}}>{isNumber(order.priceHT) ? parseFloat(order.priceHT).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-'} € HT</td>
                                                <td style={{textAlign: 'right', padding: '0 5px'}}>{isNumber(order.priceTTC) ? parseFloat(order.priceTTC).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-'} € TTC</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                        <table>
                                            <tbody style={{fontSize: 12}}>
                                            <tr>
                                                <td style={{fontWeight: 700}}>Remise</td>
                                                <td style={{textAlign: 'right', padding: '0 5px'}}>{isNumber(order.priceDiscountHT) ? parseFloat(order.priceDiscountHT).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-'} € HT</td>
                                                <td style={{textAlign: 'right', padding: '0 5px'}}>{isNumber(order.priceDiscountTTC) ? parseFloat(order.priceDiscountTTC).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-'} € TTC</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}><hr/></td>
                                            </tr>
                                            <tr>
                                                <td><span style={{fontWeight: 700}}>CB</span></td>
                                                <td>{parseFloat(order.payment.shopCard).toLocaleString('fr-FR', {minimumFractionDigits: 2}) } €</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}><hr/></td>
                                            </tr>
                                            <tr>
                                                <td><span style={{fontWeight: 700}}>Espèce</span></td>
                                                <td>{parseFloat(order.payment.shopCash).toLocaleString('fr-FR', {minimumFractionDigits: 2}) } €</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}><hr/></td>
                                            </tr>
                                            <tr>
                                                <td><span style={{fontWeight: 700}}>Avoir</span></td>
                                                <td>{parseFloat(order.payment.shopAvoir).toLocaleString('fr-FR', {minimumFractionDigits: 2}) } €</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}><hr/></td>
                                            </tr>
                                            <tr>
                                                <td><span style={{fontWeight: 700}}>Monnaie rendue</span></td>
                                                <td>{parseFloat(order.payment.cashBack).toLocaleString('fr-FR', {minimumFractionDigits: 2}) } €</td>
                                                <td></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                </Grid>
                                <br/>
                                {order.stateId === 2 &&
                                    <ButtonComponent
                                        color={'#982525'}
                                        label={'Annuler la commande'}
                                        className={classes.button}
                                        onClick={() => setShowCancelDialog(true)}
                                    />
                                }
                            </ShadowBoxComponent>
                        </Grid>
                    </Fade>
                    <Slide direction={'left'} in={true} {...{timeout: 750}}>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
                            <ShadowBoxComponent style={{height: '100%'}}>
                                <TitleComponent title={'Historique'}/>
                                <p style={{fontSize: 12, margin: 2}}><span style={{fontWeight: 700}}>Créée le : </span> {order.history.createdAt}</p><br/>
                                <p style={{fontSize: 12, margin: 2}}><span style={{fontWeight: 700}}>Confirmée le : </span> {order.history.confirmedAt}</p><br/>
                                <p style={{fontSize: 12, margin: 2}}><span style={{fontWeight: 700}}>Préparée le : </span> {order.history.inPreparationAt}</p>
                                <p style={{fontSize: 12, margin: 2}}><span style={{fontWeight: 700}}>Par : </span> {order.history.userPreparer}</p><br/>
                                <p style={{fontSize: 12, margin: 2}}><span style={{fontWeight: 700}}>Colisage le : </span> {order.history.packagingAt}</p><br/>
                                <p style={{fontSize: 12, margin: 2}}><span style={{fontWeight: 700}}>En transit le : </span> {order.history.inTransitAt}</p>
                                <p style={{fontSize: 12, margin: 2}}><span style={{fontWeight: 700}}>Code : </span> {order.history.numberCarrier}</p><br/>
                                <p style={{fontSize: 12, margin: 2}}><span style={{fontWeight: 700}}>Terminée le : </span> {order.history.completedAt}</p><br/>
                                <p style={{fontSize: 12, margin: 2}}><span style={{fontWeight: 700}}>Annulé le : </span> {order.history.cancelAt}</p>
                                <p style={{fontSize: 12, margin: 2}}><span style={{fontWeight: 700}}>Par : </span> {order.history.authorCancel}</p><br/>
                                {order.history.forwardingUuid !== '-' && <p style={{fontSize: 12, margin: 2}}><span style={{fontWeight: 700}}>Réexpédié le : </span> {order.history.forwardingAt}
                                    <Tooltip title={'Visualisation la commande réexpédiée'} placement="right">
                                        <Link to={getRoutePathname('sale_order_client', {uuid: order.history.forwardingUuid})}>
                                            <IconButton><Article style={{color: '#17a2b8', fontSize: 17, position: 'absolute', right: -5, top: -2}}/></IconButton>
                                        </Link>
                                    </Tooltip>
                                </p>}
                                {(order.history && order.history.orderClientCredits) && order.history.orderClientCredits.map((orderClientCredit, key) => {
                                    return <p style={{fontSize: 12, margin: 2}} key={key}>
                                        <span style={{fontWeight: 700}}>Remboursé le : </span> {orderClientCredit.refundAt}
                                        <Tooltip title={'Télécharger l\'avoir de la commande'} placement="right">
                                            <IconButton onClick={() => {
                                                fetch(ApiHandler.route({route: 'api_sale_order_credit_download', params: {uuid: orderClientCredit.uuid}}), {method: 'GET', mode: 'no-cors', referrerPolicy: 'no-referrer'})
                                                    .then(res => res.blob())
                                                    .then(res => {
                                                        const aElement = document.createElement('a');
                                                        aElement.setAttribute('download', orderClientCredit.uuid + '.pdf');
                                                        const href = URL.createObjectURL(res);
                                                        aElement.href = href;
                                                        aElement.setAttribute('target', '_blank');
                                                        aElement.click();
                                                        URL.revokeObjectURL(href);
                                                    });
                                            }}
                                            ><CloudDownload style={{color: '#006500', fontSize: 17, position: 'absolute', right: -5, top: -2}}/></IconButton>
                                        </Tooltip>
                                    </p>
                                })}
                            </ShadowBoxComponent>
                        </Grid>
                    </Slide>
                </Grid>

                <br/>

                <TabsComponent
                    tabDefault={'sale_order_client'}
                    tabs={[
                        {key: 'sale_order_client', label: 'Articles'},
                        {key: 'sale_order_client_litige', label: 'Litiges'},
                    ]}
                >
                    <div key={'sale_order_client'}>
                        <TableComponent
                            id={'api_sale_order_client'}
                            title={'Articles'}
                            columns={columns}
                            promiseData={(resolve) => {
                                let data = order.byProducts;

                                setByProducts(data);
                                let newForm = {};

                                for (let index in data) {
                                    data[index].amountText = isNumber(data[index].amount) ? parseFloat(data[index].amount).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                    data[index].amountShippedText = isNumber(data[index].amountShipped) ? parseFloat(data[index].amountShipped).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                    data[index].priceOriginalHTText = isNumber(data[index].priceOriginalHT) ? parseFloat(data[index].priceOriginalHT).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                    data[index].priceOriginalTTCText = isNumber(data[index].priceOriginalTTC) ? parseFloat(data[index].priceOriginalTTC).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                    data[index].priceDiscountHTText = isNumber(data[index].priceDiscountHT) ? parseFloat(data[index].priceDiscountHT).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                    data[index].priceDiscountTTCText = isNumber(data[index].priceDiscountTTC) ? parseFloat(data[index].priceDiscountTTC).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                    data[index].priceHTText = isNumber(data[index].priceHT) ? parseFloat(data[index].priceHT).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                    data[index].priceTTCText = isNumber(data[index].priceTTC) ? parseFloat(data[index].priceTTC).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';

                                    const amountLitigeLegit = data[index].amount - data[index].amountLitige;

                                    newForm[data[index].id] = {
                                        name: data[index].id,
                                        label: 'Quantité',
                                        textHelper: `Saisissez la quantité de produit en litige (maximum : (${amountLitigeLegit}))`,
                                        type: 'integer',
                                        defaultValue: 0,
                                        options: {validation: ['required'], max: amountLitigeLegit}
                                    };
                                    setForm({...form, ...newForm});
                                }
                                resolve(data);
                            }}
                        />
                    </div>
                    <div key={'sale_order_client_litige'}>
                        <TableComponent
                            id={'api_sale_order_client_litige'}
                            title={'Litiges'}
                            columns={columnsLitiges}
                            promiseData={(resolve) => {
                                setIsLitiges(false);
                                let data = [...litiges];
                                setHasLitige(false);
                                for (let index in data) {
                                    if (!data[index].dealAt) setIsLitiges(true);
                                    data[index].litigeAtText = moment(data[index].litigeAt).format('lll');
                                    data[index].dealAtText = data[index].dealAt ? moment(data[index].dealAt).format('lll') : '-';
                                    data[index].action = getAction(data[index]);
                                    setHasLitige(true);
                                }
                                resolve(data);
                            }}
                        />
                    </div>
                </TabsComponent>
                <br/>
            </>}

            <Dialog open={openDialog} maxWidth={'xl'} onClose={() => setOpenDialog(false)}>
                <DialogTitle style={{fontSize: 15}}>Ouvrir un litige</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SelectComponent id={'orderClientLitigeType'} options={optionsOrderClientLitigeType} handler={handlerForm}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent id={'reason'} handler={handlerForm} multiline/>
                        </Grid>

                        {byProducts?.map((byProduct, index) => (
                            <Grid item xs={12} key={index}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <p>{byProduct.name}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextFieldComponent id={byProduct.id} handler={handlerForm}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setOpenDialog(false)} loading={isLoadingForm}/>
                    <ButtonComponent label={'Enregistrer'} onClick={saveLitige} loading={isLoadingForm}/>
                </DialogActions>
            </Dialog>

            <Dialog open={showCancelDialog} maxWidth={'xl'} onClose={() => setShowCancelDialog(false)}>
                <DialogTitle style={{fontSize: 15}}>Annuler la commande</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <p>Êtes-vous sure de vouloir annuler la commande ?</p>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setShowCancelDialog(false)} loading={isLoadingCancel}/>
                    <ButtonComponent label={'Valider'} onClick={cancelOrder} loading={isLoadingCancel}/>
                </DialogActions>
            </Dialog>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    state: {
        backgroundColor: '#9c27b0',
        color: '#FFF',
        borderRadius: 25,
        padding: '5px 10px',
        marginLeft: 10,
        fontSize: 11,
        position: 'absolute',
        top: 8,
        right: 13
    },
    litige: {
        backgroundColor: '#F17105',
        color: '#FFF',
        borderRadius: 25,
        padding: '5px 10px',
        marginLeft: 10,
        fontSize: 11,
    },
    button: {
        margin: '15px !important',
        bottom: '0 !important',
        right: '0 !important',
        position: 'absolute !important'
    }
});

export default ShopSaleOrderClient;
