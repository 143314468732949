import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Fade, Grid, IconButton, Tooltip} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {initFormHandler} from "../../../../Handler/FormHandler";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import ApiHandler from "../../../../Handler/ApiHandler";
import {getRoutePathname} from "../../../../Handler/RouteHandler";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../../Action/LinearProgressMainLayoutAction";
import {Article, AppRegistration} from "@mui/icons-material";
import TableComponent from "../../../../Component/TableComponent";
import {TableReload} from "../../../../Action/TableAction";
import {useDispatch, useSelector} from "react-redux";
import {hasRight} from "../../../../Common/UserCommon";
import {isNumber} from "../../../../Util/NumberTool";
import SelectComponent from "../../../../Component/SelectComponent";
import AutoCompleteComponent from "../../../../Component/AutoCompleteComponent";

function BuyPurveyorByProductList(props) {
    const dispatch = useDispatch();
    const params = useParams();

    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const justWatch = !hasRight(authenticationReducer, 'ROLE_BUY_PURVEYOR') && !hasRight(authenticationReducer, 'ROLE_BUY_PURVEYOR_EDIT');

    // ByProduct List
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "sku",
            label: "SKU",
            options: {filter: true, sort: true}
        },
        {
            name: "name",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "flavor",
            label: "Parfum/Couleur",
            options: {filter: true, sort: true}
        },
        {
            name: "packSize",
            label: "Taille du conditionnement",
            options: {filter: true, sort: true}
        },
        {
            name: "lifeCycle",
            label: "Cycle de vie",
            options: {filter: true, sort: true}
        },
        {
            name: "galenic",
            label: "galénique",
            options: {filter: true, sort: true}
        },
        {
            name: "mark",
            label: "Marque",
            options: {filter: true, sort: true}
        },
        {
            name: "productType",
            label: "Type",
            options: {filter: true, sort: true}
        },
        {
            name: "sector",
            label: "Secteur",
            options: {filter: true, sort: true}
        },
        {
            name: "purveyorReference",
            label: "Référence fournisseur",
            options: {filter: true, sort: true}
        },
        {
            name: "priceText",
            label: "Prix d\'achat HT",
            options: {filter: true, sort: true}
        },
        {
            name: "shapingPriceText",
            label: "Prix de façonnage HT",
            options: {filter: true, sort: true}
        },
        {
            name: "moqText",
            label: "MOQ",
            options: {filter: true, sort: true}
        },
        {
            name: "palletizingText",
            label: "Palettisation",
            options: {filter: true, sort: true}
        },
        {
            name: "boxText",
            label: "Carton",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        if (!justWatch) {
            return (
                <div style={{width: 65}}>
                    <Tooltip title={'Modifier'} placement="left">
                        <IconButton onClick={() => {
                            handlerFormByProduct.reset();
                            handlerFormByProduct.setValue('price', row.price === 'N/A' ? '' : row.price);
                            handlerFormByProduct.setValue('shapingPrice', row.shapingPrice === 'N/A' ? '' : row.shapingPrice);
                            handlerFormByProduct.setValue('moq', row.moq === 'N/A' ? '' : row.moq);
                            handlerFormByProduct.setValue('palletizing', row.palletizing === 'N/A' ? '' : row.palletizing);
                            handlerFormByProduct.setValue('box', row.box === 'N/A' ? '' : row.box);
                            handlerFormByProduct.setValue('purveyorReference', row.purveyorReference === 'N/A' ? '' : row.purveyorReference);
                            setByProduct(row);
                        }}><AppRegistration style={{color: '#17a2b8'}}/></IconButton>
                    </Tooltip>
                    <Tooltip title={'Visualiser l\'article'} placement="left">
                        <Link to={getRoutePathname('buy_product', {id: row.productId})}>
                            <IconButton><Article style={{color: '#28a745'}}/></IconButton>
                        </Link>
                    </Tooltip>
                </div>
            );
        }
        else {
            return (
                <div style={{width: 65}}>
                    <IconButton style={{cursor: 'no-drop'}}>
                        <AppRegistration/>
                    </IconButton>
                    <IconButton style={{cursor: 'no-drop'}}>
                        <Article/>
                    </IconButton>
                </div>
            );
        }
    };

    // ByProduct price Form
    const [byProduct, setByProduct] = React.useState(null);
    const [formByProduct, setFormByProduct] = React.useState({
        purveyorReference: {
            name: 'purveyorReference',
            label: 'Référence fournisseur',
            textHelper: 'Saisissez la référence fournisseur du sous article chez ce fournisseur.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        price: {
            name: 'price',
            label: 'Prix d\'achat HT',
            textHelper: 'Saisissez le prix d\'achat du sous article chez ce fournisseur.',
            type: 'float',
            defaultValue: '',
            options: {validation: ['required']}
        },
        shapingPrice: {
            name: 'shapingPrice',
            label: 'Prix de façonnage HT',
            textHelper: 'Saisissez le prix de façonnage du sous article chez ce fournisseur.',
            type: 'float',
            defaultValue: '',
            options: {validation: ['required']}
        },
        moq: {
            name: 'moq',
            label: 'MOQ',
            textHelper: 'Saisissez le MOQ du sous article chez ce fournisseur.',
            type: 'float',
            defaultValue: '',
            options: {validation: ['required']}
        },
        palletizing: {
            name: 'palletizing',
            label: 'Palettisation',
            textHelper: 'Saisissez le nombre d\'unité par palette du sous article chez ce fournisseur.',
            type: 'float',
            defaultValue: '',
            options: {validation: ['required']}
        },
        box: {
            name: 'box',
            label: 'Carton',
            textHelper: 'Saisissez le nombre d\'unité par carton du sous article chez ce fournisseur.',
            type: 'float',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const [isLoadingFormByProduct, setIsLoadingFormByProduct] = React.useState(false);
    const handlerFormByProduct = initFormHandler(formByProduct, setFormByProduct);
    const saveByProduct = () => {
        if (handlerFormByProduct.checkError() < 1) {
            handlerFormByProduct.setFormLoading(true);
            setIsLoadingFormByProduct(true);
            dispatch(LinearProgressMainLayoutActivate());

            ApiHandler.post({
                route: 'api_buy_purveyor_by_product_change_price',
                data: handlerFormByProduct.getData(),
                params: {purveyorId: props.purveyor.id, byProductId: byProduct.id},
            }, (response) => {
                if (response.status === 200) {
                    dispatch(TableReload('api_buy_purveyor_by_product_list'));
                    setByProduct(null);
                    dispatch(
                        SnackbarOpen({
                            text: 'Prix du sous article modifié avec succès.',
                            variant: 'success',
                        })
                    );
                }
                else if (response.status === 400) {
                    handlerFormByProduct.setErrorApi(response.error);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }

                dispatch(LinearProgressMainLayoutDeactivate());
                handlerFormByProduct.setFormLoading(false);
                setIsLoadingFormByProduct(false);
            });
        }
    };

    // Product
    const [openDialog, setOpenDialog] = React.useState(false);
    const [formProduct, setFormProduct] = React.useState({
        productType: {
            name: 'productType',
            label: 'Type',
            textHelper: 'Choisissez le type de l\'article.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        mark: {
            name: 'mark',
            label: 'Marque',
            textHelper: 'Choisissez la marque de l\'article.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        product: {
            name: 'product',
            label: 'Article',
            textHelper: 'Choisissez un article.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const handlerFormProduct = initFormHandler(formProduct, setFormProduct);
    const [optionsMark, setOptionsMark] = React.useState([]);
    const [optionsProductType, setOptionsProductType] = React.useState([]);

    const loadProduct = (text, callback) => {
        ApiHandler.get({
            route: 'api_auto_complete_product',
            data: {text: text, mark: formProduct.mark.value, productType: formProduct.productType.value}
        }, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };
    const saveProduct = () => {
        let data = handlerFormProduct.getData();

        if (handlerFormProduct.checkError() < 1) {
            handlerFormProduct.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            ApiHandler.get({
                route: 'api_buy_purveyor_add_product',
                params: {id: params.id, productId: data.product}
            }, (response) => {
                if (response.status === 200) {
                    handlerFormProduct.reset();
                    setOpenDialog(null);
                    dispatch(TableReload('api_buy_purveyor_by_product_list'));
                    setTimeout(() => dispatch(TableReload('api_buy_product_by_product_purveyor_list')), 500);

                    dispatch(
                        SnackbarOpen({
                            text: 'Article ajouté au fournisseur avec succès.',
                            variant: 'success',
                        })
                    );
                }
                else if (response.status === 400) {
                    handlerFormProduct.setErrorApi(response.error);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }

                dispatch(LinearProgressMainLayoutDeactivate());
                handlerFormProduct.setFormLoading(false);
            });
        }
    };

    React.useEffect(() => {
        ApiHandler.get({route: 'api_select_mark'}, (response) => {
            let data = [{value: '', label: ''}, ...response.data];
            setOptionsMark(data)
        });
        ApiHandler.get({route: 'api_select_product_type'}, (response) => {
            let data = [{value: '', label: ''}, ...response.data];
            setOptionsProductType(data)
        });
    }, []);
    React.useEffect(handlerFormByProduct.start, []);

    return (
        <>
            <TableComponent
                id={'api_buy_purveyor_by_product_list'}
                title={'Sous articles'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter un article',
                    onClick: () => {
                        handlerFormProduct.reset();
                        setOpenDialog(true);
                    }
                }}
                promiseData={(resolve) => {
                    ApiHandler.get({
                            route: 'api_buy_purveyor_by_product_list',
                            params: {purveyorId: props.purveyor.id}
                        },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                                data[index].priceText = isNumber(data[index].price) ? parseFloat(data[index].price).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                data[index].shapingPriceText = isNumber(data[index].shapingPrice) ? parseFloat(data[index].shapingPrice).toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                data[index].moqText = isNumber(data[index].moq) ? data[index].moq.toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                data[index].palletizingText = isNumber(data[index].palletizing) ? data[index].palletizing.toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                                data[index].boxText = isNumber(data[index].box) ? data[index].box.toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                            }
                            resolve(data);
                        });
                }}
            />

            <Dialog open={!!byProduct} maxWidth={'xl'} onClose={() => setByProduct(null)}>
                <DialogTitle style={{fontSize: 15}}>{byProduct ? byProduct.name : ''}</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent id={'purveyorReference'} handler={handlerFormByProduct} adornment={<span>unité(s)</span>}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                            <TextFieldComponent id={'price'} handler={handlerFormByProduct} adornment={<span>€</span>}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                            <TextFieldComponent id={'shapingPrice'} handler={handlerFormByProduct} adornment={<span>€</span>}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                            <TextFieldComponent id={'moq'} handler={handlerFormByProduct} adornment={<span>unité(s)</span>}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                            <TextFieldComponent id={'palletizing'} handler={handlerFormByProduct} adornment={<span>unité(s)</span>}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                            <TextFieldComponent id={'box'} handler={handlerFormByProduct} adornment={<span>unité(s)</span>}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setByProduct(null)} disabled={isLoadingFormByProduct}/>
                    <ButtonComponent label={'Enregistrer'} onClick={saveByProduct} loading={isLoadingFormByProduct}/>
                </DialogActions>
            </Dialog>

            <Dialog open={openDialog} maxWidth={'xl'} onClose={() => setOpenDialog(false)}>
                <DialogTitle style={{fontSize: 15}}>{'Ajouter un article'}</DialogTitle>
                <DialogContent style={{minWidth: '40vw', height: 200}}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <SelectComponent id={'productType'} options={optionsProductType} handler={handlerFormProduct}/>
                        </Grid>
                        <Grid item xs={6}>
                            <SelectComponent id={'mark'} options={optionsMark} handler={handlerFormProduct}/>
                        </Grid>
                        <Grid item xs={12}>
                            <AutoCompleteComponent id={'product'} handler={handlerFormProduct} loadOptions={loadProduct}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setOpenDialog(false)}/>
                    <ButtonComponent label={'Enregistrer'} onClick={saveProduct}/>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default BuyPurveyorByProductList;
