import React from 'react';
import {useSelector} from "react-redux";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import {hasRight} from "../../../Common/UserCommon";
import {Grid, IconButton, Slide, Tooltip} from "@mui/material";
import {Edit, Article} from "@mui/icons-material";
import {Link} from "react-router-dom";
import { cacheTablePage, cacheTableServer } from '../../../Cache';
import { dispatch } from '../../../App';
import { TableReload } from '../../../Action/TableAction';
import TitleComponent from '../../../Component/TitleComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import { initFormHandler } from '../../../Handler/FormHandler';

function BuyPurveyorList() {
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [loadingContent, setLoadingContent] = React.useState(true);
    const [optionsPurveyorType, setOptionsPurveyorType] = React.useState([]);
    const [optionsCountry, setOptionsCountry] = React.useState([]);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "name",
            label: "Nom du fournisseur",
            options: {filter: true, sort: true}
        },
        {
            name: "address",
            label: "Adresse",
            options: {filter: true, sort: true}
        },
        {
            name: "purveyorType",
            label: "Type de fournisseur",
            options: {filter: true, sort: true}
        },
        {
            name: "paymentTerms",
            label: "Conditions de paiement",
            options: {filter: true, sort: true}
        },
        {
            name: "productionTime",
            label: "Délai de production + livraison",
            options: {filter: true, sort: true}
        },
        {
            name: "deliveryQuantityVariation",
            label: "Variation des quantités livrées",
            options: {filter: true, sort: true}
        },
        {
            name: "transport",
            label: "Franco de port",
            options: {filter: true, sort: true}
        },
        {
            name: "divalto",
            label: "Référence Divalto",
            options: {filter: true, sort: true}
        },
        {
            name: "country",
            label: "Pays",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];

    // formSearch
    const [formSearch, setFormSearch] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Rechercher par noms de fournisseur.',
            type: 'text',
            defaultValue: cacheTableServer['api_buy_purveyor_list'] && cacheTableServer['api_buy_purveyor_list'].name ? cacheTableServer['api_buy_purveyor_list'].name : '',
            options: {}
        },
        purveyorType: {
            name: 'purveyorType',
            label: 'Type de fournisseur',
            textHelper: 'Rechercher par types de fournisseur.',
            type: 'integer',
            defaultValue: cacheTableServer['api_buy_purveyor_list'] && cacheTableServer['api_buy_purveyor_list'].purveyorType ? cacheTableServer['api_buy_purveyor_list'].purveyorType : 0,
            options: {}
        },
        country: {
            name: 'country',
            label: 'Pays',
            textHelper: 'Rechercher par pays.',
            type: 'integer',
            defaultValue: cacheTableServer['api_buy_purveyor_list'] && cacheTableServer['api_buy_purveyor_list'].country ? cacheTableServer['api_buy_purveyor_list'].country : 0,
            options: {}
        }
    });
    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerFormSearch.setValue(index, value);
        cacheTablePage['api_buy_purveyor_list'] = 0;
        cacheTableServer['api_buy_purveyor_list'].page = 0;
        cacheTableServer['api_buy_purveyor_list'][index] = value;
        dispatch(TableReload('api_buy_purveyor_list'))
    };
    const getAction = (row) => {
        if ((hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT') ||
            hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT_GET'))) {
            return (
                <>
                    {
                        (hasRight(authenticationReducer, 'ROLE_BUY_PURVEYOR') || hasRight(authenticationReducer, 'ROLE_BUY_PURVEYOR_EDIT')) ?
                            <Tooltip title={'Modifier'} placement="left">
                                <Link to={getRoutePathname('buy_purveyor', {id: row.id})}>
                                    <IconButton><Edit style={{color: '#17a2b8'}}/></IconButton>
                                </Link>
                            </Tooltip> : (hasRight(authenticationReducer, 'ROLE_BUY_PURVEYOR_GET')) ?
                                <Tooltip title={'Visualiser'} placement="left">
                                    <Link to={getRoutePathname('buy_purveyor', {id: row.id})}>
                                        <IconButton><Article style={{color: '#28a745'}}/></IconButton>
                                    </Link>
                                </Tooltip> : <IconButton disabled={true}><Edit style={{color: '#17a2b8'}}/></IconButton>
                    }
                </>
            );
        }
        else {
            return (
                <>
                    {
                        (hasRight(authenticationReducer, 'ROLE_BUY_PRODUCT_EDIT')) ?
                            <IconButton style={{cursor: 'no-drop'}}>
                                <Edit/>
                            </IconButton> :
                            <IconButton style={{cursor: 'no-drop'}}>
                                <Article/>
                            </IconButton>
                    }
                </>
            );
        }
    };

    React.useEffect(() => {
        handlerFormSearch.start();

        setBreadcrumbs({
            title: 'Liste des fournisseurs',
            context: 'Achat',
            description: '',
            links: []
        });

        //get options
        ApiHandler.get({route: 'api_select_purveyor_type'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsPurveyorType(data)
        });
        ApiHandler.get({route: 'api_select_country'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsCountry(data)
        });

        setLoadingContent(false);
    }, [])

    return (
        <ContentViewComponent loading={loadingContent} breadcrumbs={breadcrumbs}>
            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'}/>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <TextFieldComponent id={'name'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('name', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                <SelectComponent id={'purveyorType'} options={optionsPurveyorType} handler={handlerFormSearch} onChange={(val) => inputSearchChange('purveyorType', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                <SelectComponent id={'country'} options={optionsCountry} handler={handlerFormSearch} onChange={(val) => inputSearchChange('country', val)}/>
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>

            <br/>

            <TableComponent
                id={'api_buy_purveyor_list'}
                title={'Fournisseurs'}
                columns={columns}
                actionFirst={hasRight(authenticationReducer, 'ROLE_BUY_PURVEYOR') || hasRight(authenticationReducer, 'ROLE_BUY_PURVEYOR_ADD') ? {
                    label: 'Ajouter un fournisseur',
                    link: getRoutePathname('buy_purveyor', {id: 'add'})
                } : false}
                promiseServerData={(resolve, options) => {
                    let datas = handlerFormSearch.getData();
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        name: formSearch.name.value ? formSearch.name.value : '',
                        purveyorType: (datas.purveyorType === 0 || !datas.purveyorType) ? '' : datas.purveyorType,
                        country: (datas.country === 0 || !datas.country) ? '' : datas.country,
                    };
                    ApiHandler.get({
                            route: 'api_buy_purveyor_list',
                            data: filters
                        },
                        (response) => {
                            let data = response.data.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                                data[index].deliveryQuantityVariation = data[index].deliveryQuantityVariation + '%';
                                if (data[index].transport === 1) {
                                    data[index].transport = 'Oui'
                                }
                                else {
                                    data[index].transport = 'Non'
                                }
                            }
                            resolve(data, response.data.count);
                        });
                }}
            />
            
            <br/><br/>
        </ContentViewComponent>
    );
}

export default BuyPurveyorList;
