import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import moment from "moment";
import { cacheTablePage, cacheTableServer } from '../../../Cache';
import { Grid, Slide } from '@mui/material';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../Component/TitleComponent';
import { initFormHandler } from '../../../Handler/FormHandler';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import SelectComponent from '../../../Component/SelectComponent';
import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import {isNumber} from "../../../Util/NumberTool";

function InventoryManagementMovementList() {
    const [loadingContent, setLoadingContent] = React.useState(true);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [optionsPurveyor, setOptionsPurveyor] = React.useState([]);
    const [optionsDeposit, setOptionsDeposit] = React.useState([]);
    const [optionsChannel, setOptionsChannel] = React.useState([]);
    const [optionsMovementAction, setOptionsMovementAction] = React.useState([]);
    const [optionsMark, setOptionsMark] = React.useState([]);
    const [optionsPackSize, setOptionsPackSize] = React.useState([]);
    const [optionsFlavor, setOptionsFlavor] = React.useState([]);
    const moveColumns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "byProductSku",
            label: "SKU",
            options: {filter: true, sort: true}
        },
        {
            name: "productName",
            label: "Article",
            options: {filter: true, sort: true}
        },
        {
            name: "mark",
            label: "Marque",
            options: {filter: true, sort: true}
        },
        {
            name: "packSize",
            label: "Conditionnement",
            options: {filter: true, sort: true}
        },
        {
            name: "flavor",
            label: "Parfum",
            options: {filter: true, sort: true}
        },
        {
            name: "amountText",
            label: "Quantité",
            options: {filter: true, sort: true}
        },
        {
            name: "purveyor",
            label: "Fournisseur",
            options: {filter: true, sort: true}
        },
        {
            name: "deposit",
            label: "Dépôt",
            options: {filter: true, sort: true}
        },
        {
            name: "channel",
            label: "Canal",
            options: {filter: true, sort: true}
        },
        {
            name: "comment",
            label: "Commentaire",
            options: {filter: true, sort: true}
        },
        {
            name: "batch",
            label: "Lot",
            options: {filter: true, sort: true}
        },
        {
            name: "movementAction",
            label: "Action",
            options: {filter: true, sort: true}
        },
        {
            name: "createdAt",
            label: "Date",
            options: {filter: true, sort: true}
        }
    ];

    // formSearch
    const [formSearch, setFormSearch] = React.useState({
        byProductSku: {
            name: 'byProductSku',
            label: 'SKU',
            textHelper: 'Rechercher par codes.',
            type: 'text',
            defaultValue: cacheTableServer['api_inventory_management_movement_list'] && cacheTableServer['api_inventory_management_movement_list'].sku ? cacheTableServer['api_inventory_management_movement_list'].sku : '',
            options: {}
        },
        purveyor: {
            name: 'purveyor',
            label: 'Fournisseur',
            textHelper: 'Rechercher par fournisseurs.',
            type: 'integer',
            defaultValue: cacheTableServer['api_inventory_management_movement_list'] && cacheTableServer['api_inventory_management_movement_list'].purveyor ? cacheTableServer['api_inventory_management_movement_list'].purveyor : 0,
            options: {}
        },
        deposit: {
            name: 'deposit',
            label: 'Dépot',
            textHelper: 'Rechercher par dépots.',
            type: 'integer',
            defaultValue: cacheTableServer['api_inventory_management_movement_list'] && cacheTableServer['api_inventory_management_movement_list'].deposit ? cacheTableServer['api_inventory_management_movement_list'].deposit : 0,
            options: {}
        },
        channel: {
            name: 'channel',
            label: 'Canal',
            textHelper: 'Rechercher par canaux.',
            type: 'integer',
            defaultValue: cacheTableServer['api_inventory_management_movement_list'] && cacheTableServer['api_inventory_management_movement_list'].channel ? cacheTableServer['api_inventory_management_movement_list'].channel : 0,
            options: {}
        },
        movementAction: {
            name: 'movementAction',
            label: 'Action',
            textHelper: 'Rechercher par actions.',
            type: 'integer',
            defaultValue: cacheTableServer['api_inventory_management_movement_list'] && cacheTableServer['api_inventory_management_movement_list'].movementAction ? cacheTableServer['api_inventory_management_movement_list'].movementAction : 0,
            options: {}
        },
        batch: {
            name: 'batch',
            label: 'Lot',
            textHelper: 'Rechercher par lots.',
            type: 'text',
            defaultValue: cacheTableServer['api_inventory_management_movement_list'] && cacheTableServer['api_inventory_management_movement_list'].batch ? cacheTableServer['api_inventory_management_movement_list'].batch : '',
            options: {}
        },
        mark: {
            name: 'mark',
            label: 'Marque',
            textHelper: 'Rechercher par marque d\'article.',
            type: 'integer',
            defaultValue: cacheTableServer['api_inventory_management_movement_list'] && cacheTableServer['api_inventory_management_movement_list'].mark ? cacheTableServer['api_inventory_management_movement_list'].mark : 0,
            options: {}
        },
        byProduct: {
            name: 'byProduct',
            label: 'Article',
            textHelper: 'Rechercher par nom d\'article.',
            type: 'text',
            defaultValue: cacheTableServer['api_inventory_management_movement_list'] && cacheTableServer['api_inventory_management_movement_list'].byProduct ? cacheTableServer['api_inventory_management_movement_list'].byProduct : '',
            options: {}
        },
        packSize: {
            name: 'packSize',
            label: 'Conditionnement',
            textHelper: 'Rechercher par conditionnement.',
            type: 'integer',
            defaultValue: cacheTableServer['api_inventory_management_movement_list'] && cacheTableServer['api_inventory_management_movement_list'].packSize ? cacheTableServer['api_inventory_management_movement_list'].packSize : 0,
            options: {}
        },
        flavor: {
            name: 'flavor',
            label: 'Parfum',
            textHelper: 'Rechercher par parfum.',
            type: 'integer',
            defaultValue: cacheTableServer['api_inventory_management_movement_list'] && cacheTableServer['api_inventory_management_movement_list'].flavor ? cacheTableServer['api_inventory_management_movement_list'].flavor : 0,
            options: {}
        },

    });
    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerFormSearch.setValue(index, value);
        cacheTablePage['api_inventory_management_movement_list'] = 0;
        cacheTableServer['api_inventory_management_movement_list'].page = 0;
        cacheTableServer['api_inventory_management_movement_list'][index] = value;
        dispatch(TableReload('api_inventory_management_movement_list'))
    };

    React.useEffect(() => {
        handlerFormSearch.start();

        setBreadcrumbs({
            title: 'Liste des mouvements',
            context: 'Gestion des stocks',
            description: '',
            links: []
        });

        //get options MovementList
        ApiHandler.get({route: 'api_select_purveyor'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsPurveyor(data)
        });
        ApiHandler.get({route: 'api_select_deposit'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsDeposit(data)
        });
        ApiHandler.get({route: 'api_select_channel'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsChannel(data)
        });
        ApiHandler.get({route: 'api_select_movement_action'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsMovementAction(data)
        });
        ApiHandler.get({route: 'api_select_mark'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsMark(data)
        });
        ApiHandler.get({route: 'api_select_pack_size'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsPackSize(data)
        });
        ApiHandler.get({route: 'api_select_flavor'}, (response) => {
            let data = [{value: 0, label: 'Tous'}, ...response.data];
            setOptionsFlavor(data)
        });

        setLoadingContent(false);
    }, [])

    return (
        <ContentViewComponent loading={loadingContent} breadcrumbs={breadcrumbs}>

            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'} />
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={4}>
                                <TextFieldComponent id={'byProductSku'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('byProductSku', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                <SelectComponent id={'purveyor'} options={optionsPurveyor} handler={handlerFormSearch} onChange={(val) => inputSearchChange('purveyor', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                <SelectComponent id={'deposit'} options={optionsDeposit} handler={handlerFormSearch} onChange={(val) => inputSearchChange('deposit', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                <SelectComponent id={'channel'} options={optionsChannel} handler={handlerFormSearch} onChange={(val) => inputSearchChange('channel', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                                <SelectComponent id={'movementAction'} options={optionsMovementAction} handler={handlerFormSearch} onChange={(val) => inputSearchChange('movementAction', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                <TextFieldComponent id={'byProduct'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('byProduct', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                                <SelectComponent id={'mark'} options={optionsMark} handler={handlerFormSearch} onChange={(val) => inputSearchChange('mark', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                                <SelectComponent id={'packSize'} options={optionsPackSize} handler={handlerFormSearch} onChange={(val) => inputSearchChange('packSize', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                                <SelectComponent id={'flavor'} options={optionsFlavor} handler={handlerFormSearch} onChange={(val) => inputSearchChange('flavor', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                                <TextFieldComponent id={'batch'} handler={handlerFormSearch} onChange={(val) => inputSearchChange('batch', val)}/>
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>

            <br></br>

            <TableComponent
                id={'api_inventory_management_movement_list'}
                title={'Mouvements'}
                columns={moveColumns}
                promiseServerData={(resolve, options) => {
                    let datas = handlerFormSearch.getData();
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        byProductSku: formSearch.byProductSku.value ? formSearch.byProductSku.value : '',
                        purveyor: (datas.purveyor === 0 || !datas.purveyor) ? '' : datas.purveyor,
                        deposit: (datas.deposit === 0 || !datas.deposit) ? '' : datas.deposit,
                        channel: (datas.channel === 0 || !datas.channel) ? '' : datas.channel,
                        movementAction: (datas.movementAction === 0 || !datas.movementAction) ? '' : datas.movementAction,
                        batch: formSearch.batch.value ? formSearch.batch.value : '',
                        byProduct: formSearch.byProduct.value ? formSearch.byProduct.value : '',
                        mark: (datas.mark === 0 || !datas.mark) ? '' : datas.mark,
                        flavor: (datas.flavor === 0 || !datas.flavor) ? '' : datas.flavor,
                        packSize: (datas.packSize === 0 || !datas.packSize) ? '' : datas.packSize,
                    };

                    ApiHandler.get({
                            route: 'api_inventory_management_movement_list',
                            data: filters
                        },
                        (response) => {
                            let data = response.data.data;
                            for (let index in data) {
                                data[index].purveyor = data[index].purveyor ?? '-';
                                data[index].channel = data[index].channel ?? '-';
                                data[index].comment = data[index].comment ?? '-';
                                data[index].createdAt = moment(data[index].createdAt).format('lll');
                                data[index].amountText = isNumber(data[index].amount) ? data[index].amount.toLocaleString('fr-FR', {minimumFractionDigits: 2}) : '-';
                            }
                            resolve(data, response.data.count);
                        });
                }}
                search={false}
            />
            <br/><br/>
        </ContentViewComponent>
    );
}

export default InventoryManagementMovementList;
