import React from 'react';
import ContentViewShopComponent from "../../../Component/ContentViewShopComponent";
import ApiHandler from "../../../Handler/ApiHandler";
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {getRoutePathname} from "../../../Handler/RouteHandler";
import {dispatch} from '../../../App';
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import Paper from "@mui/material/Paper";
import ButtonComponent from "../../../Component/ButtonComponent";

function EditReappro() {
    const navigate = useNavigate();
    const [byProducts, setByProducts] = React.useState([]);
    const [reappro, setReappro] = React.useState([]);
    const [reload, setReload] = React.useState(false);
    const params = useParams();

    const sendToPreparation = () => {
        if(reappro.id != null) {
            ApiHandler.post({
                route: 'api_shop_management_shop_reappro_convert',
                params: {id: reappro.id }
            },
            (response) => {
                dispatch(
                    SnackbarOpen({
                        text: 'La commande a été créé sur l\'espace préparation de commandes.',
                        variant: 'success',
                    })
                );

                navigate(getRoutePathname('shop_management_shop_list_reappro'));
            });
        }
    };

    const sendToCar = () => {
        if(reappro.id != null) {
            ApiHandler.post({
                    route: 'api_shop_management_shop_reappro_validate_transfer',
                    params: {id: reappro.id }
                },
                (response) => {
                    dispatch(
                        SnackbarOpen({
                            text: 'Réappro validée, vous pouvez déplacez les stocks à l entrepot cible.',
                            variant: 'success',
                        })
                    );

                    navigate(getRoutePathname('shop_management_shop_list_reappro'));
                });
        }
    };

    const refuseTransfer = () => {
        if(reappro.id != null) {
            ApiHandler.post({
                    route: 'api_shop_management_shop_reappro_refuse_transfer',
                    params: {id: reappro.id }
                },
                (response) => {
                    dispatch(
                        SnackbarOpen({
                            text: 'Réappro refusée avec succès.',
                            variant: 'success',
                        })
                    );

                    navigate(getRoutePathname('shop_management_shop_list_reappro'));
                });
        }
    };

    React.useEffect(() => {
        ApiHandler.get({
            route: 'api_shop_management_shop_reappro_edit',
            params: {id: params.id}
        }, (response) => {
            if (response.status === 200) {
                setReappro(response.data);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });

        ApiHandler.post({
            route: 'api_shop_management_shop_reappro_products_edit',
            params: {id: params.id}
        }, (response) => {
            if (response.status === 200) {
                params.byProducts = response.data.byProducts;

                setByProducts(response.data.byProducts);
            }
        });
    }, [reload, params.id]);
    return (
        <ContentViewShopComponent>
            {reappro && byProducts && <>
                <br/>
                <div style={{padding: '10px', background: '#17a2b8', color: '#fff'}}>
                    <TableContainer component={Paper} id={"byproductsList"}>
                        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}}>Référence</TableCell>
                                    <TableCell style={{fontSize: 14}} component="th" scope="row">{reappro.id}</TableCell>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}}>Status</TableCell>
                                    <TableCell style={{fontSize: 14}} component="th" scope="row">{reappro.reapproStateLabel}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}}>Date</TableCell>
                                    <TableCell style={{fontSize: 14}} component="th" scope="row">{reappro.createdAt}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}}>Dépôt d'origine</TableCell>
                                    <TableCell style={{fontSize: 14}} component="th" scope="row">{reappro.deposit}</TableCell>
                                    <TableCell style={{fontWeight: 900, fontSize: 12}}>Destination</TableCell>
                                    <TableCell style={{fontSize: 14}} component="th" scope="row">{reappro.fromDeposit}</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>
                </div>
                <br/>
                <div style={{textAlign: 'right'}}>
                    {reappro && reappro.deposit == "Entrepôt" && (reappro.reapproState != 3 && reappro.reapproState != 4 && reappro.reapproState != 2 && reappro.reapproState != 6) && <>
                        <ButtonComponent label={'Valider'} style={{margin: '5px'}} onClick={sendToPreparation}/>
                        <ButtonComponent label={'Supprimer'} style={{margin: '5px'}} onClick={refuseTransfer}/>
                    </>}
                    {reappro && reappro.deposit != "Entrepôt" && (reappro.reapproState != 4 && reappro.reapproState != 2 && reappro.reapproState != 6) && <>
                        <ButtonComponent label={'Valider le transfert de stock'} style={{margin: '5px'}} onClick={sendToCar}/>
                        <ButtonComponent label={'Supprimer'} style={{margin: '5px'}} onClick={refuseTransfer}/>
                    </>}
                </div>
                <hr/>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TableContainer component={Paper} id={"byproductsList"}>
                            <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight: 900, fontSize: 12}}>SKU</TableCell>
                                        <TableCell style={{fontWeight: 900, fontSize: 12}} align="left">Nom</TableCell>
                                        <TableCell style={{fontWeight: 900, fontSize: 12}} align="left">Marque</TableCell>
                                        <TableCell style={{fontWeight: 900, fontSize: 12}} align="left">Parfum</TableCell>
                                        <TableCell style={{fontWeight: 900, fontSize: 12}} align="left">Format</TableCell>
                                        <TableCell style={{fontWeight: 900, fontSize: 12}} align="left">Qte demandée</TableCell>
                                        <TableCell style={{fontWeight: 900, fontSize: 12}} align="left">Qte reçue</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(byProducts.length > 0) ? byProducts.map((row) => {
                                        return (
                                            <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                <TableCell style={{fontSize: 11}} component="th" scope="row">{row.sku}</TableCell>
                                                <TableCell style={{fontSize: 11}} align="left">{row.name}</TableCell>
                                                <TableCell style={{fontSize: 11}} align="left">{row.mark}</TableCell>
                                                <TableCell style={{fontSize: 11}} align="left">{row.flavor}</TableCell>
                                                <TableCell style={{fontSize: 11}} align="left">{row.packSize}</TableCell>
                                                <TableCell style={{fontSize: 11}} align="left">{row.amountRequest}</TableCell>
                                                <TableCell style={{fontSize: 11}} align="left">{row.amountReceive}</TableCell>
                                            </TableRow>
                                        )
                                    }) : <TableRow key={1} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell colSpan={7} style={{textAlign: 'center'}}>Aucun résultat.</TableCell>
                                    </TableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </>}
        </ContentViewShopComponent>
    );
}

export default EditReappro;
